.med {
	position: fixed;
	padding: 9px 29px 17px;
	border-radius: 103px;
	z-index: 1000;
	transition: background 0.2s linear, backdrop-filter 0.2s linear;

	&__is-show {
		display: inline-block; }

	&__is-hide {
		display: none; }

	&__is-bg {
		gap: 8px;
		background: rgba(255, 255, 255, 0.3);
		background-blend-mode: multiply;
		backdrop-filter: blur(20px);
		-webkit-backdrop-filter: blur(20px); }

	svg {
		width: 136px;
		height: 40px; } }

