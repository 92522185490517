.instrument-sales {
	position: relative;
	height: calc(100vh - 56px);
	max-height: 990px;
	max-width: 1260px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	transition: margin .2s ease;
	border-radius: 32px;
	background: linear-gradient(90deg, #FFD02C 0%, #F8C123 100%);
	//box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), inset 0px -1px 0px rgba(0, 0, 0, 0.25)

	@include media-breakpoint-down(md) {
		height: calc(100vh - 32px);
		max-height: 660px;
		margin-right: -16px;
		margin-left: -16px;
		padding: 0 30px; }

	&__wrapper-soty {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		border-radius: 32px;
		overflow: hidden; }

	&__soty {
		position: absolute;
		bottom: 0;
		right: 0;
		max-width: 1260px; }

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center; }

	&__new {
		@include body-1-regular-desktop;
		text-decoration: none;
		border-radius: 23px;
		padding: 4px 24px;
		background-color: $white;

		@include media-breakpoint-down(md) {
			@include body-1-regular-mobile; }

		&:hover {
			color: inherit; } }

	&__title {
		@include title-1-desktop;
		max-width: 996px;
		padding: 0 40px;
		margin: 12px 0 32px!important;

		@include media-breakpoint-down(md) {
			@include title-1-mobile;
			margin: 16px 0;
			padding: 0; } }

	&__text {
		@include body-1-medium-desktop;
		max-width: 790px;
		margin: 0 250px;

		@include media-breakpoint-down(lg) {
			margin: 0 100px; }
		@include media-breakpoint-down(md) {
			@include body-1-medium-mobile;
			margin: 0; } }

	&__rotate {
		max-width: 160px;
		height: 178px;
		position: absolute;
		bottom: 45px;
		right: 45px;

		svg {
			filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
			transform: rotate(7deg); }

		@include media-breakpoint-down(md) {
			max-width: 120px;
			bottom: -7%;
			left: 3%;

			svg {
				transform: rotate(-7deg); } } } }
