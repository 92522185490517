.bg-white-custom,
.bg-white-wrapper {
	background-color: $white; }

.bg-linear-wrapper {
	background: linear-gradient(180deg, #F4EDE8 0%, #FFFEFA 100%), linear-gradient(180deg, #FCF5F1 0%, #FFFEFA 100%), linear-gradient(180deg, #FFFEFA 0%, #F8F4EC 100%) !important;

	@include media-breakpoint-down(md) {
		background: unset; } }

.bg-black-custom {
	background-color: $black;
	overflow: hidden; }

.section-layout {
	padding: 28px;
	@include media-breakpoint-down(md) {
		padding: 16px 28px; } }

.wrapper-med {
	position: absolute;
	top: 0;
	left: 0;
	padding: 20px;
	transition: top 0.2s linear;

	@include media-breakpoint-down(md) {
		left: 50%;
		padding: 0;
		top: 0;
		display: flex;
		justify-content: center;
		margin-top: 14px; } }

.wr-horizontal-scroll {
	max-width: 1280px;
	overflow-x: scroll;
	margin-left: -24px;
	margin-right: -24px; }

.wr-horizontal-scroll::-webkit-scrollbar {
	width: 0;
	background: transparent; }
.sr-only {
	display: none; }
@mixin img-responsive {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto; }
