// Starter template
@import './page/page';
@import "./header/header";
@import "./logo/logo";
@import "./mainmenu/mainmenu";
@import "./hamburger/hamburger";
@import "./breadcrumbs/breadcrumbs";
@import "./content/content";
@import "./sidebar/sidebar";
@import "./module/module";
@import './textblock/textblock';
@import './pagination/pagination';
@import "./footer/footer";
@import "./overlay/overlay";
// @import "./preloader/preloader"

// Developer components
@import "instrument-sales/instrument-sales";
@import "card-information/card-information";
@import "payment/payment";
@import "connection/connection";
@import "addition/addition";
@import "form/form";

// Partnership
@import "instrument-sales-partnership/instrument-sales-partnership";
@import "card-information-partnership/card-information-partnership";
@import "payment-partnership/payment-partnership";
@import "chain-partnership/chain-partnership";
@import "purchase-partnership/purchase-partnership";
@import "addition-partnership/addition-partnership";
@import "form-partnership/form-partnership";
