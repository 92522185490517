.card-information-partnership {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin-top: 36px;
  margin-bottom: 180px;

  @include media-breakpoint-down(md) {
    padding: 0;
    margin-top: 64px;
    margin-bottom: 144px; }

  &__card {
    width: 551px;
    height: 347px;
    margin-top: 122px;
    margin-bottom: 85px;
    transition: transform 1s linear;

    &--transform {
      transform: rotate(-16deg); }

    @include media-breakpoint-down(md) {
      width: 290px;
      height: 182px;
      margin-top: 50px;
      margin-bottom: 40px; } }

  &__img {
    @include img-responsive; }

  &__title {
    @include title-3-desktop;
    max-width: 996px;
    text-align: center;

    @include media-breakpoint-down(md) {
      @include title-3-mobile; } }

  &__text {
    @include body-1-regular-desktop;
    text-align: center;
    max-width: 1050px;

    @include media-breakpoint-down(md) {
      @include body-1-regular-mobile; } } }
