.addition-partnership {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin: 0 auto 72px;
  max-width: 78.75rem;
  position: relative;
  z-index: 50;

  @include media-breakpoint-down(lg) {
    padding: 0 20px; }

  &__title {
    @include title-2-desktop;

    max-width: 610px;
    background: linear-gradient(90deg, #B34DC9 22.76%, #78B4E0 37.88%, #F8CA2B 63.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include media-breakpoint-down(lg) {
      @include title-2-mobile; } }

  &__bee {
    -webkit-text-fill-color: initial; }

  &__subtitle {
    color: rgba(255, 255, 255, 0.55) !important;

    @include body-2-medium-desktop;

    margin-bottom: 76px;

    @include media-breakpoint-down(lg) {
      @include body-2-medium-mobile;

      margin-bottom: 35px; } }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      width: calc(100% + 94px);
      display: flex;
      align-items: flex-start;
      justify-content: normal;
      flex-wrap: nowrap;
      margin: 1rem 0;
      overflow-x: scroll;
      padding: 0 0 0 1rem;
      scroll-padding-left: 1rem;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none; } } }

  &__item {
    @include media-breakpoint-down(lg) {
      padding: 0 1rem 0 0;
      scroll-snap-align: center;
      scroll-snap-stop: always;

      &:nth-child(1) {
        padding-left: 70px; }
      &:nth-child(3) {
        padding-right: 70px; } } }

  &__block {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    margin: 10px;
    z-index: 2;

    &:nth-child(1) {
      position: relative; }

    &+& {
      margin: 10px; }

    @include media-breakpoint-down(lg) {
      margin: 0; } }

  &__picture {
    width: 269px;
    height: 582px;

    @include media-breakpoint-down(lg) {
      width: 237px;
      height: 511px; } }

  &__img {
    @include img-responsive; }

  &__text {
    max-width: 273px;
    color: $white !important;
    text-align: center;

    @include body-2-medium-desktop;

    margin-top: 16px;

    &:nth-child(2) {
      margin-top: 50px !important; }

    @include media-breakpoint-down(lg) {
      @include body-2-medium-mobile; } }

  &__blue {
    position: absolute;
    top: 320px;
    left: 50px;
    width: 533px;
    height: 467px;


    @include media-breakpoint-down(lg) {
      top: 160px;
      left: 16px;
      width: calc(100% - 88px); }

    svg {
      background: #184F76;
      filter: blur(70px);
      border-radius: 40px;
      transform: matrix(0.85, 0.6, -0.48, 0.83, 0, 0); } }

  &__green {
    position: absolute;
    bottom: -10px;
    right: 100px;
    width: 227px;
    height: 263px;

    @include media-breakpoint-down(lg) {
      bottom: -180px;
      right: -100px; }

    svg {
      background: linear-gradient(180deg, #32B124 0%, rgba(50, 177, 36, 0) 100%);
      opacity: 0.4;
      filter: blur(18px);
      border-radius: 40px;
      transform: matrix(0.91, -0.04, -0.53, 1, 0, 0); } } }
