.instrument-sales-partnership {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: margin .2s ease;

  &__wrapper {
    max-width: 1260px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 30px;
    @include media-breakpoint-down(md) {
      margin: 0; } }

  &__header {
    display: flex;
    flex-direction: column; }

  &__header,
  &__top,
  &__content,
  &__video-content,
  &__bottom {
    width: 100%; }

  &__video-content {
    padding-top: 36px;
    @include media-breakpoint-down(md) {
      padding-top: 0; } }

  &__header,
  &__top,
  &__bottom {
    flex-grow: 1; }

  &__header {
    background: linear-gradient(180deg, #F4EDE8 0%, #FCF5F1 100%); }

  &__video-content {
    background: linear-gradient(180deg, #FCF5F1 0%, #FFFEFA 100%);
    overflow: hidden; }

  &__bottom {
    background: linear-gradient(180deg, #FFFEFA 0%, #F8F4EC 100%); }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }

  &__title {
    @include title-2-desktop;
    max-width: 996px;
    padding: 0 40px;
    margin-bottom: 12px;

    @include media-breakpoint-down(md) {
      @include title-2-mobile;
      padding: 0; } }

  &__text {
    @include title-3-desktop;
    text-align: center;

    @include media-breakpoint-down(md) {
      @include title-3-mobile;
      max-width: 340px;
      padding-bottom: 34px; } }

  &__video {
    width: 100%;
    max-width: 1090px;
    margin: auto;
    height: 370px;

    @include media-breakpoint-down(md) {
      width: 619px;
      height: 210px;
      margin-left: -100%;
      margin-right: -100%;
      text-align: center; } }


  &__rotate {
    max-width: 160px;
    height: 178px;
    position: absolute;
    bottom: 45px;
    right: 45px;

    svg {
      filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
      transform: rotate(0); }

    @include media-breakpoint-down(md) {
      max-width: 120px;
      bottom: -2%;
      left: 3%;

      svg {
        transform: rotate(-28deg); } } } }
