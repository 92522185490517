.chain-partnership {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 175px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
      padding: 0; }

    &__title {
      @include title-2-desktop;
      margin-bottom: 28px;

      @include media-breakpoint-down(md) {
        @include title-2-mobile;
        margin-bottom: 16px; } }

    &__subtitle {
      @include body-1-regular-desktop;
      max-width: 996px;
      margin-bottom: 60px;
      text-align: center !important;

      @include media-breakpoint-down(md) {
        @include body-1-regular-mobile; } }

    &__picture {
        max-width: 948px; }

    &__img {
      @include img-responsive; } }

