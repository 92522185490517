.card-information {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
	margin-top: 160px;
	margin-bottom: 300px;

	@include media-breakpoint-down(md) {
		padding: 0;
		margin-top: 120px;
		margin-bottom: 120px; }

	&__card {
		width: 551px;
		height: 347px;
		margin-top: 122px;
		margin-bottom: 85px;
		transition: transform 1s linear;

		&--transform {
			transform: rotate(-16deg); }

		@include media-breakpoint-down(md) {
			width: 290px;
			height: 182px;
			margin-top: 50px;
			margin-bottom: 40px; } }

	&__img {
		@include img-responsive; }

	&__title {
		@include title-2-desktop;
		max-width: 1100px;

		@include media-breakpoint-down(md) {
			@include title-2-mobile; } }

	&__text {
		@include body-1-regular-desktop;
		text-align: center;
		max-width: 1168px;

		@include media-breakpoint-down(md) {
			@include body-1-regular-mobile; } } }
