.hide {
  display: none !important; }

.form-partnership {
  position: relative;
  max-width: 730px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 70px 80px;
  background: rgba(96, 96, 112, 0.3);
  border-radius: 36px;
  margin: 0 auto 110px;

  @include media-breakpoint-down(md) {
    background-color: transparent;
    margin-top: 120px;
    margin-bottom: 96px;
    padding: 0; }

  &__stores {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: 2rem auto 0 auto;

    .googleplay__icon__image {
      width: 11.44rem;
      height: 4.12rem; }
    .appstore__icon__image {
      width: 10.43rem;
      height: 4.12rem; } }

  &__title {
    color: $white !important;
    margin-bottom: 28px;

    @include title-4-desktop;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;

      @include title-4-mobile; } }

  &__subhead {
    color: rgba(255, 255, 255, 0.55) !important;
    text-align: center;
    margin-bottom: 42px;

    @include body-2-medium-desktop;

    @include media-breakpoint-down(md) {
      margin-bottom: 28px;

      @include body-2-medium-mobile; } }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5; }

  &__block {
    &:last-child {
      margin-bottom: 44px; } }

  &__labels {
    text-align: center;
    margin-bottom: 33px;

    @include media-breakpoint-down(md) {
      margin-bottom: 29px; } }

  &__label {
    color: rgba(255, 255, 255, 0.7) !important;
    cursor: pointer;

    &:hover {
      color: #FFD74B !important;

      @include media-breakpoint-down(md) {
        color: $white !important; } }

    &--active {
      display: inline-block;
      padding-bottom: 6px;
      border-bottom: 3px solid #FFD74B;
      color: $white !important; }

    &:first-of-type {
      margin-right: 20px;

      @include media-breakpoint-down(md) {
        margin-right: 33px; } }

    @include body-2-medium-desktop;

    @include media-breakpoint-down(md) {
      @include body-2-semi-bold-mobile; } }

  &__form {
    &--active {
      display: block; }
    &--hide {
      display: none; } }

  &__error {
    display: block;
    color: #F65D3C !important;
    margin-top: 8px;
    @include body-2-regular-desktop;

    @include media-breakpoint-down(md) {
      @include body-2-regular-mobile; } }

  &__button {
    @include body-2-semi-bold-desktop;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    padding: 17px 16px;
    background-color: #FFD74B;
    margin-top: 44px;
    margin-bottom: 28px;
    outline: none;
    border: none;
    transition: background-color 0.2s linear;

    &--hide-text {
      text-indent: -9999px; }

    &--show-text {
      text-indent: initial; }

    &:hover {
      background-color: #F7C517; }

    @include media-breakpoint-down(md) {
      @include body-2-semi-bold-mobile;

      margin-top: 24px;
      height: 50px;
      margin-bottom: 20px; } }

  &__subtitle, &__service {
    color: rgba(255, 255, 255, 0.55) !important;
    text-align: center;

    @include body-2-medium-desktop;

    @include media-breakpoint-down(md) {
      @include body-2-medium-mobile; } }

  &__link {
    padding-left: 5px;
    color: $white;
    transition: color 0.2s linear;

    &:hover {
      color: #FFD74B; } }

  &__red {
    position: absolute;
    left: -35%;
    top: 25%;
    width: 268px;
    height: 234px;


    @include media-breakpoint-down(md) {
      top: -50%;
      left: -20%; }

    svg {
      background: linear-gradient(75.7deg, #EB5625 6.24%, rgba(235, 86, 37, 0) 83.21%);
      opacity: 0.56;
      filter: blur(18px);
      border-radius: 40px;
      transform: matrix(0.63, 0.84, -0.71, 0.64, 0, 0); } }

  &__violet {
    position: absolute;
    bottom: 100px;
    right: -17%;
    max-width: 303px;
    max-height: 352px;

    @include media-breakpoint-down(lg) {
      bottom: 17%;
      right: 0; }

    svg {
      background: linear-gradient(201.53deg, #8C67DA 6.4%, rgba(140, 103, 218, 0) 75.93%);
      opacity: 0.7;
      filter: blur(42px);
      border-radius: 40px;
      transform: matrix(0.67, 0.48, -1.28, 0.58, 0, 0); } } }

input {
  @include body-2-regular-desktop;

  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 0.125rem solid $white;
  padding: 17px 16px;
  background-color: $white;
  outline: none;
  transition: border .2s linear;

  &::placeholder {
    @include body-2-regular-desktop; }

  &:focus {
    border: 2px solid #F2BD13; }

  @include media-breakpoint-down(md) {
    @include body-2-regular-mobile;

    height: 48px; }

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px $white !important;
    -webkit-text-fill-color: #261D00 !important;
    color: #261D00 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    @include media-breakpoint-down(md) {
      font-size: 19px !important; } } }

.error {
  &:invalid {
    border: 2px solid #D93F0C; } }

.success {
  border: 2px solid $white; }


.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: block;
  opacity: 0;

  &--background {
    @include media-breakpoint-down(md) {
      background-color: #ACACAC;
      transition: background-color 1000ms; } } }


.popup {
  position: relative;
  margin: 0 auto;
  top: 25%;
  padding: 64px;
  background: #FFFFFF;
  border-radius: 36px;
  width: 688px;
  transition: all 5s ease-in-out;

  @include media-breakpoint-down(md) {
    max-width: 358px;
    padding: 40px 28px;

    @include title-4-mobile; }

  &__title {
    color: #362B07 !important;
    margin-bottom: 28px;
    @include title-4-desktop;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      @include title-4-mobile; } }

  &__text {
    color: #362B07 !important;
    margin-bottom: 40px;
    text-align: center;
    @include body-1-regular-desktop;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
      @include body-1-regular-mobile; } }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    padding: 17px 16px;
    background-color: #FFD74B;
    text-decoration: none;
    outline: none;
    border: none;
    transition: background-color 0.2s linear;
    color: #261D00 !important;

    @include body-2-semi-bold-desktop;

    @include media-breakpoint-down(md) {
      @include body-2-semi-bold-mobile; }

    &:hover {
      background-color: #F7C517; } }

  &__active {
    display: block;
    opacity: 1;
    z-index: 10000; }

  &__hide {
    display: none;
    opacity: 0; } }

.spinner {
  width: 44px;
  height: 44px;

  &--active {
    display: block; }

  &--hide {
    display: none; }

  &__item {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 2.5px solid #030D25;
    border-radius: 50%;
    animation: spinner_rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #030D25 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s; }

    &:nth-child(2) {
      animation-delay: -0.3s; }

    &:nth-child(3) {
      animation-delay: -0.15s; } } }

@keyframes spinner_rotate {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }
