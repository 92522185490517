/* Titles
   @mixin ======================================================================== */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
    margin: 0 0 20px;
    line-height: 1.2;
    font-weight: 700;

    &:not(:first-child) {
        margin-top: 20px; } }

p {
	margin: 0; }
/**
 * Responsive titles
 */
h1, .h1 {
    /* 36px - 24px */
    @include responsive-tlt(24px, 36px); }

h2, .h2 {
    /* 30px - 22px */
    @include responsive-tlt(22px, 30px); }

h3, .h3 {
    /* 26px - 20px */
    @include responsive-tlt(20px, 26px); }

h4, .h4 {
    /* 24px - 18px */
    @include responsive-tlt(18px, 24px); }

h5, .h5 {
    /* 20px - 17px */
    @include responsive-tlt(17px, 20px); }

h6, .h6 {
    /* 18px - 16px */
    @include responsive-tlt(16px, 18px); }

/**
 * Lists
 */
ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0; }

@mixin title-1-desktop {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    font-size: 68px;
    line-height: 68px;
    letter-spacing: -2%;
    color: #261D00; }
@mixin title-2-desktop {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    font-size: 62px;
    line-height: 65.1px;
    letter-spacing: -2%;
    color: #261D00;
    text-align: center; }

@mixin title-3-desktop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 105%;
    letter-spacing: -0.02em;
    color: #261D00; }

@mixin title-4-desktop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 105%;
    letter-spacing: -0.02em;
    color: #261D00;
    text-align: center; }

@mixin body-1-bold-desktop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    color: #261D00; }

@mixin body-1-semi-bold-desktop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    color: #261D00; }

@mixin body-1-medium-desktop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    color: #261D00; }

@mixin body-1-regular-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-bold-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-semi-bold-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-medium-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-regular-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 120%;
	color: #261D00; }

@mixin body-3-regular-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 120%;
	color: #261D00; }

@mixin sticker-label-desktop {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 112%;
	text-align: center;
	color: #261D00; }

@mixin title-1-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 38px;
	line-height: 100%;
	letter-spacing: -0.02em;
	color: #261D00; }

@mixin title-2-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 105%;
	letter-spacing: -0.02em;
	color: #261D00;
	text-align: center; }

@mixin title-3-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 34px;
	line-height: 105%;
	letter-spacing: -0.02em;
	color: #261D00; }

@mixin title-4-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 105%;
	letter-spacing: -0.02em;
	color: #261D00 {
    text-align: center; } }

@mixin body-1-bold-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	color: #261D00; }

@mixin body-1-semi-bold-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 120%;
	color: #261D00; }

@mixin body-1-medium-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	color: #261D00; }

@mixin body-1-regular-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-bold-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-semi-bold-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-medium-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	color: #261D00; }

@mixin body-2-regular-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
	color: #261D00; }

@mixin body-3-regular-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
	color: #261D00; }

@mixin sticker-label-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 112%;
	color: #261D00; }
