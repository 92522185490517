.purchase-partnership {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin: 120px auto 100px;
  max-width: 78.75rem;


  @include media-breakpoint-down(lg) {
    margin-top: 80px;
    margin-bottom: 96px;
    padding: 0; }

  &__title {
    @include title-2-desktop;
    max-width: 590px;
    margin-bottom: 62px;
    background: linear-gradient(90deg, #FFD02C 0%, #88C563 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include media-breakpoint-down(lg) {
      @include title-2-mobile;
      margin-bottom: 32px; } }

  &__information {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 56px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      margin-bottom: 40px; } }

  &__info {
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &:first-child {
        margin-top: 0; } } }


  &__counter {
    svg {
      width: 50px;
      height: 56px; }

    @include media-breakpoint-down(lg) {
      svg {
        width: 42px;
        height: 47px; } } }

  &__report {
    color: $white !important;
    margin-top: 16px;

    @include body-2-medium-desktop;

    @include media-breakpoint-down(lg) {
      @include body-2-medium-mobile;

      margin-top: 0;
      margin-left: 12px; } }

  &__months {
    color: rgba(255, 255, 255, 0.55) !important;

    @include body-2-medium-desktop;

    @include media-breakpoint-down(lg) {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 110%; } }

  &__buying, &__dash {
    color: rgba(255, 255, 255, 0.55) !important;

    @include body-1-medium-desktop;

    @include media-breakpoint-down(lg) {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 110%; } }

  &__technique {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      height: 140px;
      word-break: break-word; } }


  &__picture {
    margin-right: 24px;

    @include media-breakpoint-down(lg) {
      margin-left: 5px; }
    img {
      width: 72px;

      @include media-breakpoint-down(lg) {
        width: 50px;
        margin-bottom: 11px; } } }

  &__name {
    color: white !important;
    margin-bottom: 4px;
    @include body-1-regular-desktop;

    @include media-breakpoint-down(lg) {
      font-weight: 500;
      font-size: 15px;
      line-height: 110%;
      margin-bottom: 6px; }

    &--mobile {
      margin-right: -6px; } }

  &__cost {
    font-family: 'Inter';
    color: rgba(255, 255, 255, 0.55) !important;
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;

    @include media-breakpoint-down(lg) {
      font-style: italic;
      font-size: 12px;
      line-height: 110%; }

    &-text {
      color: rgba(255, 255, 255, 0.55) !important;

      @include body-2-medium-desktop;

      @include media-breakpoint-down(lg) {
        @include body-2-medium-mobile; } } }

  &__money {
    color: white !important;
    @include body-1-bold-desktop;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
      line-height: 110%; } }

  &__exchange {
    color: rgba(255, 255, 255, 0.55) !important;
    @include body-1-medium-desktop;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
      line-height: 110%; } }

  &__sales {
    &--mobile {
      padding-left: 10px; } }

  &__purchase-desktop {
    max-width: 100%;
    width: 100%;
    display: block;

    @include media-breakpoint-down(lg) {
      display: none; }

    &-table {
      max-width: 1200px;
      white-space: nowrap; }

    &-tr {
      &:last-of-type {
        border-top: 1px solid rgba(255, 255, 255, 0.3); } }

    &-th {
      padding: 0 35px 15px; }

    &-td {
      padding: 0 35px 38px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        padding-right: 0; }
      &--pb {
        padding-bottom: 30px; }
      &--pt {
        padding-top: 8px; } } }

  &__purchase-mobile {
    display: none;
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center; }

    &-table {
      max-width: 100%; }

    &-th {
      padding: 0 6px 6px;

      &:nth-of-type(4) {
        position: relative;
        padding-left: 8px;
        @media only screen and (max-width: 375px) {
          padding: 0; } }

      &:nth-of-type(4)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60%;
        border-left: 1px solid rgba(255, 255, 255, 0.25); }

      @media only screen and (max-width: 375px) {
        padding: 0; } }

    &-td {
      padding: 0 6px 6px;
      text-align: center;
      &:first-child {
        text-align: left;
        padding-left: 0; }
      &:last-child {
        padding-right: 0; }

      &:nth-of-type(4) {
        position: relative;
        padding-left: 0; }

      &:nth-of-type(4)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        border-left: 1px solid rgba(255, 255, 255, 0.25); }

      &--padding {
        padding-bottom: 0; }

      @media only screen and (max-width: 375px) {
        padding-left: 0; } } } }
