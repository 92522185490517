.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 40px;
	margin-bottom: 28px;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-down(md) {
		padding: 0; }

	&__content {
		display: flex;
		flex-direction: column; }

	&__block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 19px; }

	&__logo {
		width: 137px;
		height: 53px; }

	&__social-links {
		display: flex;
		align-items: center; }

	&__social-link {
		width: 32px;
		height: 32px;
		cursor: pointer;

		&:first-child {
			margin-right: 20px; }

		svg {
			transition: opacity 0.2s linear;

			&:hover {
				filter: brightness(0) saturate(100%) invert(78%) sepia(97%) saturate(2009%) hue-rotate(322deg) brightness(113%) contrast(100%); } } }

	&__info, &__links {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 120%;
		color: rgba(255, 255, 255, 0.55);

		&:last-child {
			margin-top: 4px; }

		@include media-breakpoint-down(md) {
			font-size: 14px; }

		svg {
			width: 92px;
			height: 14px; } }

	&__links > a {
		color: rgba(255, 255, 255, 0.55);
		transition: color 0.2s linear;

		&:hover {
			color: #FFD74B; }

		&:first-child {
			margin-right: 10px; } } }
