.connection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
	margin: 92px auto 210px;
	max-width: 78.75rem;


	@include media-breakpoint-down(md) {
		margin-top: 80px;
		margin-bottom: 150px;
		padding: 0; }

	&__title {
		@include title-2-desktop;
		max-width: 590px;
		margin-bottom: 62px;
		background: linear-gradient(90deg, #FFD02C 0%, #88C563 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@include media-breakpoint-down(md) {
			@include title-2-mobile;
			margin-bottom: 32px; } }

	&__content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;

		@include media-breakpoint-down(md) {
			justify-content: center; } }

	&__block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 370px;
		height: 370px;
		padding: 20px 24px 32px;
		border-radius: 32px;
		margin: 10px;

		&+& {
			margin: 10px; }

		&:nth-child(1) {
			background-color: #32B124; }
		&:nth-child(2) {
			background-color: #8C67DA; }
		&:nth-child(3) {
			background-color: #EB5625; }
		&:nth-child(4) {
			background-color: #ECBA0B; }
		&:nth-child(5) {
			background-color: #32B124; }
		&:nth-child(6) {
			position: relative;
			background-color: #8C67DA; }

		@include media-breakpoint-down(md) {
			height: 278px;
			margin-left: 0;
			margin-right: 0;

			&+& {
				margin-left: 0;
				margin-right: 0; } } }

	&__counter {
		width: 64px;
		height: 71px; }

	&__picture {
		width: 140px;
		height: 140px;

		@include media-breakpoint-down(md) {
			width: 80px;
			height: 80px; } }


	&__img {
		@include img-responsive; }

	&__subtitle {
		color: $white !important;
		margin: 8px 0;

		&--margin {
			margin-right: -4px; }

		@include body-2-bold-desktop;

		@include media-breakpoint-down(md) {
			@include body-2-bold-mobile; } }

	&__text {
		color: $white !important;

		&--margin {
			margin-right: -4px; }

		@include body-2-medium-desktop;

		@include media-breakpoint-down(md) {
			@include body-2-medium-mobile; } }

	&__rotate {
		width: 160px;
		position: absolute;
		bottom: -28%;
		right: -2%;

		svg {
			transform: rotate(7deg); }

		@include media-breakpoint-down(md) {
			width: 120px;
			bottom: -35%; } } }
