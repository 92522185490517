.payment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 40px;
	margin-bottom: 272px;
	max-width: 78.75rem;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-down(md) {
		margin-bottom: 92px;
		padding: 0; }

	&__title {
		@include title-2-desktop;
		margin-bottom: 60px;

		@include media-breakpoint-down(md) {
			@include title-2-mobile;
			margin-bottom: 32px; } }

	&__content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include media-breakpoint-down(lg) {
			justify-content: center;
			flex-wrap: wrap; } }

	&__block {
		max-width: 355px;

		@include media-breakpoint-down(lg) {
			margin-bottom: 50px; } }

	&__img {
		@include img-responsive; }

	&__subtitle {
		@include body-2-semi-bold-desktop;
		text-align: center !important;
		margin: 20px 0 8px;
		padding-right: 32px;

		@include media-breakpoint-down(md) {
			@include body-2-semi-bold-mobile;
			margin: 8px 0;
			padding-right: 0; } }

	&__text {
		@include body-2-regular-desktop;
		text-align: center !important;
		padding-right: 32px;

		@include media-breakpoint-down(md) {
			@include body-2-regular-mobile;
			padding-right: 0; } } }

